<template>
	<div>
		<p>{{$t('ticket.sendNewTicket.labelSubject')}} : {{ticket.title}}</p>
		<p>{{$t('ticket.sendNewTicket.labelContent')}} : {{ticket.description}}</p>
		<template v-for="item in ticket.logs">
			<p :class="['ticket','mb-10','font-bold',!item.admin_id ? 'mine' : '']">{{item.content}}</p>
		</template>
		<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
			<a-form-item class="mb-10">
				<a-input
					v-decorator="[
					'content',
					{ rules: [{ required: true, message: `${$t('rulesForm.requiredSubjectTicket')}!` }] },
					]"
					type="textarea"
					:placeholder="$t('ticket.ticketOfYou.label')"
				>
				</a-input>
			</a-form-item>
			<a-form-item>
				<a-button type="primary" block html-type="submit" class="login-form-button">
					{{$t('ticket.sendNewTicket.title')}}
				</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<style type="text/css" scoped>
	.ticket{
		background: grey;
		border-radius: 5px;
		color: #fff;
		clear: both;
		float: right;
		max-width: 70%;
		margin: 5px 0;
		padding: 5px 10px;
		width: fit-content;
	}
	.mine{
		background: blue;
		float: left;
	}
</style>
<script>
	import TicketService from '@/services/ticket'

	export default ({
		components: {
		},
		data() {
			return {
				ticket : {
					logs : []
				},
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created(){
			this.getData()
		},
		methods:{
			async getData(){
				const ticket = await TicketService.detail(this.$route.params.id)
				if(ticket.status == 1){
					this.ticket = ticket.data
				}
			},
			handleSubmit(e){
				e.preventDefault();
				let thiss = this
				this.form.validateFields(async (err, values) => {
					if ( !err ) {
						let formData = new FormData();
						formData.append('content', values.content);
						await TicketService.chat(this.$route.params.id,formData)
						thiss.getData()
						thiss.form.resetFields()
					}
				});
			}
		}
	})

</script>