import listing from './listing'
import create from './create'
import detail from './detail'
import chat from './chat'

export default {
  listing,
  create,
  detail,
  chat,
}
